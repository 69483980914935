import { isLoginExpiredAtom } from "atoms";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { SenderService } from "services";
import { generateBlobURL } from "utils";
import { useEffectOnce } from "./use-effect-once";

const senderService = new SenderService();

/**
 * Custom hook to fetch and manage the business icon URL for a user.
 *
 * @function useBusinessIcon
 *
 */
export const useBusinessIcon = () => {
  const [urlOperation, setUrlOperation] = useState({});
  const setIsLoginExpired = useSetAtom(isLoginExpiredAtom);

  /**
   * Fetches the user's business icon and sets the icon url.
   *
   * @async
   * @function getUserImage
   * @returns {Promise<void>}
   */
  const getUserImage = async () => {
    try {
      const stream = await senderService.getUserFiles(9);
      const { url, revokeURL } = generateBlobURL(stream);

      setUrlOperation({
        revokeURL,
        url,
      });
    } catch (error) {
      console.error(error);
      if (error.errorMessage === "expired-token") setIsLoginExpired(true);
    }
  };

  useEffectOnce(() => {
    getUserImage();

    return () => urlOperation.revokeURL && urlOperation.revokeURL();
  });

  return { urlOperation };
};
