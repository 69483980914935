/**
 * Creates a marker icon configuration.
 *
 * @param {string} url    The URL of the marker icon.
 * @param {number} width  The width of the marker icon.
 * @param {number} height The height of the marker icon.
 *
 * @returns {object}      The marker icon configuration.
 */
export const createMarkerIcon = (url, width, height) => ({
  anchor: new window.google.maps.Point(10, 10),
  origin: new window.google.maps.Point(0, 0),
  scaledSize: new window.google.maps.Size(width, height),
  url: url,
});
