export default {
  collected: "تم الاستلام (في انتظار التسليم)",
  dashboard: "لوحة التحكم",
  from: "من",
  homePageSubheader: "ألق نظرة على إحصائيات عملك",
  invalidDateRange: "المدى الزمني غير صحيح",
  myOrders: "طلباتي",
  outForDelivery: "جاهز للتسليم (بدأ التسليم)",
  pendingDelivery: "في انتظار التسليم",
  to: "إلى",
  todaysDeliveries: "تسليمات اليوم",
  tomorrowsDeliveries: "تسليمات الغد",
  totalDeliveredOrder: "إجمالي الطلبات المسلمة",
  totalOrder: "إجمالي الطلبات",
  welcomeBack: "مرحبًا بعودتك",
};
