import { styled } from "styled-components";
import { device } from "styles/breakpoints";

export const Container = styled.div`
  bottom: 0%;
  box-sizing: border-box;
  color: var(--legal-text-color);
  display: flex;
  justify-content: space-between;
  padding: 0rem 7.5rem 0rem 1.5rem;
  position: absolute;
  width: 69.1137566%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;

  @media ${device.laptop} {
    position: relative;
    width: 100%;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

export const LegalText = styled.span`
  padding: 0.625rem;
`;

export const LanguageInputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
`;

export const Option = styled.div`
  text-align: center;
  box-sizing: border-box;
`;

export const DropdownIcon = styled.img`
  position: absolute;
  pointer-events: none;
  top: 20%;
  right: 0%;
  transform: translateY(-50%);
  transform: rotate(180deg);
`;
