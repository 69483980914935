export default {
  backToLogin: "Back to Login",
  confirm: "Confirm",
  emailNotFoundError: "Please enter previously used email address.",
  forgetPassword: "Forget password?",
  forgetPasswordHeader: "Forget Password",
  newPasswordSubHeader: "Please enter a new password.",
  popupSuccessMessage: "Your password is changed successfully!",
  resetPassword: "Reset Password",
};
