export default {
  collected: "Collected (Pending Delivery)",
  dashboard: "Dashboard",
  from: "From",
  homePageSubheader: "Take a look on your business statistics",
  invalidDateRange: "Invalid Date Range",
  myOrders: "My Orders",
  outForDelivery: "Out For Delivery (Delivery started)",
  pendingDelivery: "Pending Delivery",
  to: "To",
  todaysDeliveries: "Today’s Deliveries",
  tomorrowsDeliveries: "Tomorrow’s Deliveries",
  totalDeliveredOrder: "Total Delivered Orders",
  totalOrder: "Total Orders",
  welcomeBack: "Welcome back",
};
