export default {
  backToLogin: "العودة إلى تسجيل الدخول",
  confirm: "تأكيد",
  emailNotFoundError: "الرجاء إدخال عنوان بريد الإلكتروني مستخدم سابقًا",
  forgetPassword: "نسيت كلمة المرور؟",
  forgetPasswordHeader: "نسيت كلمة المرور",
  newPasswordSubHeader: "الرجاء ادخال كلمة مرور جديدة",
  popupSuccessMessage: "لقد تم تغيير كلمة المرور الخاصة بك بنجاح!",
  resetPassword: "إعادة تعيين كلمة المرور",
};
