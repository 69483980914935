import {
  businessSetupStepAtom,
  isBusinessSetupCompleteAtom,
  userStatusAtom,
} from "atoms";
import { userStatusEnum } from "constants";
import { useAtomValue, useSetAtom } from "jotai";

export const useRedirectRoutes = (path) => {
  const userStatus = useAtomValue(userStatusAtom);
  const setIsBusinessSetupComplete = useSetAtom(isBusinessSetupCompleteAtom);
  const setBusinessSetupStep = useSetAtom(businessSetupStepAtom);
  let redirectRoute = "";
  switch (path) {
    case "/":
    case "/register":
      if (userStatus) {
        if (userStatus === userStatusEnum.pendingForApproval) {
          setIsBusinessSetupComplete(true);
          redirectRoute = "/setup";
        } else if (userStatus === userStatusEnum.pending) {
          setBusinessSetupStep(0);
          setIsBusinessSetupComplete(false);
          redirectRoute = "/setup";
        } else if (userStatus === userStatusEnum.registered) {
          redirectRoute = "/verify";
        } else {
          redirectRoute = "/dashboard";
        }
      }
      break;
    case "/setup":
      if (!userStatus) redirectRoute = "/";
      else if (userStatus === userStatusEnum.registered)
        redirectRoute = "/verify";
      else if (userStatus === userStatusEnum.pendingForApproval) {
        setIsBusinessSetupComplete(true);
      } else if (userStatus === userStatusEnum.active) {
        redirectRoute = "/dashboard";
      }
      break;
    case "/verify":
      if (userStatus !== userStatusEnum.registered) redirectRoute = "/";
      break;
    case "/dashboard":
      if (userStatus !== userStatusEnum.active) redirectRoute = "/";
      break;
    default:
      redirectRoute = "";
      break;
  }

  return redirectRoute;
};
