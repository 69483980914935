import BaseService from "./base-service";

/**
 * AuthService is responsible for handling authentication and user management related operations.
 *
 * @class AuthService
 * @extends {BaseService}
 */
export default class AuthService extends BaseService {
  path = "user-mgmt/sender";

  /**
   * Checks if the given email is available or already in use.
   *
   * @param {string} email The email address to check.
   *
   * @returns {Promise}
   */
  checkEmail = async (email) =>
    await this.get(`${this.path}/checkemail`, `?email=${email}`);

  /**
   * Registers a new user with the provided signup information.
   *
   * @param {Object} body The user's signup information.
   *
   * @returns {Promise}
   */
  signUp = async (body) => await this.post(`${this.path}/SignUp`, body);

  /**
   * Verifies the user's email with a verification code.
   *
   * @param {Object} body The verification information including the code.
   *
   * @returns {Promise}
   */
  verify = async (body) =>
    await this.post(`${this.path}/SignUpVerification`, body);

  /**
   * Logs a user.
   *
   * @param {Object} body The login credentials.
   *
   * @returns {Promise}
   */
  login = async (body) => await this.post(`${this.path}/login`, body);

  /**
   * Completes the user's profile after signup.
   *
   * @param {Object} body The profile information to complete.
   *
   * @returns {Promise}
   */
  completeProfile = async (body) =>
    await this.post(`${this.path}/completeProfile`, body);

  /**
   * Change the user's password.
   *
   * @param {Object} body The currentPassword, newPassword and confirmedPassword data.
   *
   * @returns {Promise}
   */
  changePassword = async (body) =>
    this.post(`${this.path}/resetpassword`, body);

  /**
   * Forget password first step.
   *
   * @param {Object} body Endpoint body (email)
   *
   * @returns {Promise}
   */
  forgetPassword = async (body) =>
    this.post(`${this.path}/forgetpassword`, body);

  /**
   * Forget password second step OTP check.
   *
   * @param {Object} body Endpoint body (email, otpCode)
   *
   * @returns {Promise}
   */
  forgetPasswordOtpCheck = async (body) =>
    this.post(`${this.path}/forgetpassword/otp`, body);

  /**
   * Forget password third step reset password.
   *
   * @param {Object} body Endpoint body (newPassword, confirmedPassword)
   *
   * @returns {Promise}
   */
  forgetPasswordReset = async (body) =>
    this.post(`${this.path}/forgetpassword/reset`, body);
}
