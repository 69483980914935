import { ENGLISH_LANG } from "constants";
import { css, keyframes, styled } from "styled-components";
import { device } from "styles/breakpoints";

const businessIconSizes = {
  lg: css`
    height: 6.375rem;
    width: 6.375rem;
  `,
  sm: css`
    height: 3.125rem;
    width: 3.125rem;
  `,
};

export const MainLogo = styled.img`
  @media ${device.laptop} {
    align-self: center;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  margin: 100px auto;
  font-size: ${(props) => (props.size ? `${props.size}` : `1.5625rem`)};
  width: ${(props) => (props.size ? `${props.size}` : `1em`)};
  height: ${(props) => (props.size ? `${props.size}` : `1em`)};
  border-radius: 50%;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99999;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  border: 0.25em solid rgba(0, 0, 0, 0.1);
  border-top: 0.25em solid var(--blue-primary-color);
  animation: ${spin} 1s linear infinite;
`;

export const PageWrapper = styled.div`
  min-height: ${(props) => props.$isAuthPage && "100vh"};
  position: relative;
`;

export const PageContainer = styled.div`
  background-color: var(--page-background-color);
  box-sizing: border-box;
`;

export const PageContentWrapper = styled.div`
  box-sizing: border-box;
  margin-left: ${(props) =>
    props.$language === ENGLISH_LANG
      ? props.$isNavbarMinimized
        ? "6.125rem"
        : "var(--sidebar-width)"
      : "0"};

  margin-right: ${(props) =>
    props.$language !== ENGLISH_LANG
      ? props.$isNavbarMinimized
        ? "6.125rem"
        : "var(--sidebar-width)"
      : "0"};
  overflow: hidden;
  min-height: calc(100vh - var(--navbar-height));
  margin-top: var(--navbar-height);
  transition: margin 0.1s linear;
  padding: ${(props) =>
    props.$language === ENGLISH_LANG
      ? "4.25rem 7.5625rem 0rem 5.25rem"
      : "4.25rem 5.25rem 0rem 7.5625rem"};

  @media ${device.laptop} {
    margin-left: 0;
    margin-right: 0;
    padding: 2.0625rem 1.5rem 1.5625rem;
    padding: ${(props) =>
      props.$language === ENGLISH_LANG
        ? "2.0625rem 1.5rem 1.5625rem"
        : "1.5625rem 1.5rem 2.0625rem"};
  }
`;

export const PopUpWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const Text = styled.h6`
  color: ${({ $color }) => $color || "var(--dark-blue-color)"};
  font-size: ${({ $fontSize }) => $fontSize || "1rem"};
  font-weight: ${({ $fontWeight }) => $fontWeight || "400"};
  line-height: ${({ $lineHeight }) => $lineHeight || "1.5rem"};
  margin-top: ${({ $marginTop }) => $marginTop || "0"};
  align-self: var(--global-align);

  @media ${device.mobile} {
    font-size: ${({ $fontSizeMobile }) => $fontSizeMobile || "1rem"};
  }
`;

export const OrdersCreationForm = styled.form`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  flex: 1;
  direction: var(--global-grid-direction);
`;

export const DropdownIcon = styled.img`
  position: absolute;
  top: ${(props) => (props.$top ? props.$top : "70%")};
  right: ${(props) => props.$language === ENGLISH_LANG && "1.0519rem"};
  left: ${(props) => props.$language !== ENGLISH_LANG && "1.0519rem"};
  pointer-events: none;
  transform: translateY(-50%);
`;

export const FormContainer = styled.div`
  padding: ${(props) => (props.$padding ? props.$padding : "0rem")};
`;

export const SidebarPopUp = styled.div`
  direction: var(--global-grid-direction);

  overflow-y: auto;
  position: fixed;
  box-shadow: ${(props) =>
    !props.$isOpen
      ? "0rem"
      : props.$language === ENGLISH_LANG
      ? "-1.4375rem 0rem 1.3375rem 0rem rgba(0, 0, 0, 0.21)"
      : "1.4375rem 0rem 1.3375rem 0rem rgba(0, 0, 0, 0.21)"};
  z-index: 50;
  right: ${(props) => (props.$language === ENGLISH_LANG ? "0" : "auto")};
  left: ${(props) => (props.$language !== ENGLISH_LANG ? "0" : "auto")};
  top: 0;
  padding: 3.675rem 2.875rem;
  background-color: var(--white-color);
  transform: ${(props) =>
    !props.$isOpen
      ? `translateX(${props.$language === ENGLISH_LANG ? "100%" : "-100%"})`
      : "translateX(0)"};
  height: 100vh;
  transition: transform 0.1s linear;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${(props) => (props.$width ? props.$width : "36.6875rem")};

  @media ${device.mobile} {
    width: 100%;
    padding: 1.25rem 0.625rem;
  }
`;

export const Label = styled.label`
  color: var(--dark-blue-color);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6875rem;

  @media ${device.mobile} {
    font-size: 1rem;
  }
`;

export const BusinessIcon = styled.img`
  border-radius: 50%;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
  ${({ $size }) => ($size ? businessIconSizes[$size] : businessIconSizes.sm)}
`;
export const StatusLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  padding: 0.1875rem 0.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.625rem;
  background-color: ${(props) => `rgba(${props.$color},0.16)`};
  color: ${(props) => `rgba(${props.$color})`};
  border: 1px solid ${(props) => `rgba(${props.$color})`};
  white-space: nowrap;
`;
