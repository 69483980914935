import React from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

import { useTranslationHook } from "hooks";

import {
  Container,
  DropdownIcon,
  LanguageInputContainer,
  LegalText,
  Option,
} from "./footer.styles";

import dropDownIcon from "assets/language-drop-down-menu.svg";
import { LANGUAGES_OPTIONS } from "../../../constants";

/**
 * Render Footer component
 *
 * @return {JSX.Element}
 */
function Footer() {
  const { language, handleLanguageChange } = useTranslationHook();
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const formattedYear = currentYear.toString();

  /**
   * Format option label function.
   *
   * @param {object} FieldObject React select object.
   *
   * @returns {JSX.Element} Option component.
   */
  const formatOptionLabel = ({ label }) => <Option>{label}</Option>;

  return (
    <Container>
      <LegalText>{t("footerLegalText", { year: formattedYear })}</LegalText>
      <LanguageInputContainer>
        <ReactSelect
          value={LANGUAGES_OPTIONS.find((option) => option.value === language)}
          onChange={(option) => handleLanguageChange(option.value)}
          formatOptionLabel={formatOptionLabel}
          options={LANGUAGES_OPTIONS}
          menuPlacement="top"
          isSearchable={false}
          styles={{
            control: () => ({
              backgroundColor: "transparent",
              color: "rgba(60, 57, 80, 1)",
              cursor: "pointer",
              display: "flex",
              fontSize: "0.875rem",
              fontWeight: "500",
              height: "2.5rem",
              lineHeight: "1.0669rem",
              overflow: "visible",
              paddingRight: "0.875rem",
              width: "5.0625rem",
              zIndex: 1,
            }),
            dropdownIndicator: () => ({
              display: "none",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--white-color)",
              border: "0.5px solid rgba(164, 164, 164, 1)",
              borderRadius: "0.25rem 0.25rem 0px 0px",
              bottom: 40,
              left: 0,
              padding: "0rem 0.625rem",
              position: "absolute",
              zIndex: 1,
            }),
            option: (provided, state) => ({
              ...provided,
              "&:active": {
                backgroundColor: "transparent",
              },
              backgroundColor: state.isSelected && "white",
              borderBottom:
                state.label === "English" && "1px solid rgba(164, 164, 164, 1)",
              color: state.isSelected && "rgba(60, 57, 80, 1)",
              cursor: "pointer",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "inherit",
            }),
          }}
        />
        <DropdownIcon src={dropDownIcon} alt="drop-down" />
      </LanguageInputContainer>
    </Container>
  );
}

export default Footer;
