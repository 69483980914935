export default {
  Today: "اليوم",
  Tomorrow: "غدا",
  Yesterday: "أمس",
  action: "العملية",
  addressBook: "دليل العناوين",
  all: "كل الطلبات",
  apply: "تطبيق",
  arrivedAtDropOff: "وصل للتسليم",
  arrivedAtPickUp: "وصل للاستلام",
  awaitingSelection: "في انتظار الاختيار",
  cancelOrder: "تم إلغاء الطلب",
  cancelOrderHeader: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
  cancelOrderSubHeader: "لن تتمكن من تعديله بعد ذلك",
  cancelled: "ملغي",
  chat: "دردشة",
  contactReceiver: "اتصل بالعميل",
  create: "إنشاء",
  createExpedited: "إنشاء طلب مستعجل",
  createRegular: "إنشاء طلب",
  createdDate: "تاريخ الإنشاء",
  date: "تاريخ",
  delete: "حذف",
  deleteOrderHeader: "هل أنت متأكد أنك تريد حذف المسودة",
  deleteOrderSnackBar: "تم حذف الطلب",
  deleteOrderSubHeader: "لن تتمكن من إسترجاعه بعد ذلك",
  delivered: "تم التوصيل",
  draft: "مسودة",
  driverChangeApproved: "تمت الموافقة على تغيير السائق",
  dropOffColumnName: "وقت التسليم ت/و",
  edit: "تحرير",
  expedited: "مستعجل",
  expeditedOrder: "طلب مستعجل",
  failed: "فشل",
  filter: "فلتر",
  goToLocations: "الذهاب إلى المواقع",
  helpCenter: "مركز المساعدة",
  home: "الصفحة الرئيسية",
  import: "إدخال",
  incompleteData: "بيانات غير مكتملة",
  locationMissing: "!الموقع مفقود",
  locationMissingSubHeader: ".يرجى إنشاء موقعك الأول لبدء إنشاء طلباتك",
  loginExpiredMainText: "انتهت صلاحية جلستك",
  loginExpiredSubText: "سيتم توجيهك إلى صفحة تسجيل الدخول",
  logout: "تسجيل خروج",
  myContacts: "جهات الاتصال",
  myProfile: "ملفي الشخصي",
  no: "لا",
  noOrdersYet: "لا توجد طلبات حتى الآن!",
  noOrdersYetSubText: 'ابدأ وضع طلباً عن طريق "ادخال" أو "إنشاء" طلب جديد.',
  noResultFound: "لم يتم العثور على نتائج!",
  noResultFoundSubText:
    "لا توجد نتائج تطابق الفلاتر المحددة. يرجى إزالة الفلتر أو “إعادة تعيين الفلاتر” لعرض النتائج.",
  notifications: "إشعارات",
  ok: "موافق",
  onHold: "معلق",
  orderCollected: "جمع الطلب",
  orderEditedSuccessfully: "تم تعديل الطلب بنجاح",
  orderId: "رقم الطلب",
  orderOutForDelivery: "خرج للتوصيل",
  orderPlacedSuccessfully: "تم تقديم الطلب بنجاح",
  orderSavedSuccessfully:"تم حفظ الطلب بنجاح",
  orderStatus: "حالة الطلب",
  orderType: "نوع الطلب",
  ordersManagement: "إدارة الطلبات",
  ordersTracking: "تتبع الطلبات",
  pendingPickUp: "في انتظار أخذ الطلب ",
  phoneNo: "رقم الهاتف",
  pickUpColumnName: " وقت الاستلام ت/و",
  pickUpStarted: "تم بدء الاستلام",
  publish: "نشر",
  readyForDelivery: "جاهز للتوصيل",
  receiverName: "اسم المستلم",
  regular: "عادي",
  regularOrder: "طلب عادي",
  rejected: "مرفوض",
  requestDriverChange: "طلب تغيير السائق",
  reschaduled: "معاد جدولته",
  resetFilters: "إعادة ضبط",
  returned: "مرتجع",
  searchPlaceholder: "البحث عن طريق اسم المستلم، رقم الهاتف و رقم الطلب",
  settings: "إعدادات",
  status: "الحالة",
  support: "الدعم",
  tableNavigationMessageFirstPart: "عرض 1 -",
  tableNavigationMessageSecondPart: "من أصل ",
  tableNavigationMessageThirdPart: " طلب",
  view: "عرض",
  viewProfile: "عرض الملف الشخصي",
  yes: "نعم",
};
