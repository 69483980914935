import setupAr from "./business-setup/ar";
import setupEn from "./business-setup/en";
import forgetPasswordAr from "./forget-password/ar";
import forgetPasswordEn from "./forget-password/en";
import homePageAr from "./home-page/ar";
import homePageEn from "./home-page/en";
import loginAr from "./login/ar";
import loginEn from "./login/en";
import myContactAr from "./my-contacts/ar";
import myContactEn from "./my-contacts/en";
import myLocationAr from "./my-locations/ar";
import myLocationEn from "./my-locations/en";
import myProfileAr from "./my-profile/ar";
import myProfileEn from "./my-profile/en";
import orderCreationAr from "./order-creation/ar";
import orderCreationEn from "./order-creation/en";
import orderManagmentAr from "./order-managment/ar";
import orderManagmentEn from "./order-managment/en";
import regerstrationAr from "./registeration/ar";
import regerstrationEn from "./registeration/en";
import settingsAr from "./settings-page/ar";
import settingsEn from "./settings-page/en";
import sharedAr from "./shared/ar";
import sharedEn from "./shared/en";
import trackOrderAr from "./track-order/ar";
import trackOrderEn from "./track-order/en";

export default {
  ar: {
    translation: {
      ...regerstrationAr,
      ...loginAr,
      ...setupAr,
      ...sharedAr,
      ...orderManagmentAr,
      ...orderCreationAr,
      ...myContactAr,
      ...myLocationAr,
      ...myProfileAr,
      ...trackOrderAr,
      ...settingsAr,
      ...forgetPasswordAr,
      ...homePageAr,
    },
  },
  en: {
    translation: {
      ...regerstrationEn,
      ...loginEn,
      ...setupEn,
      ...sharedEn,
      ...orderManagmentEn,
      ...orderCreationEn,
      ...myContactEn,
      ...myLocationEn,
      ...myProfileEn,
      ...trackOrderEn,
      ...settingsEn,
      ...forgetPasswordEn,
      ...homePageEn,
    },
  },
};
