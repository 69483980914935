import { atom } from "jotai";
import { TokenUtils } from "utils";

export const businessSetupStepAtom = atom(0);

export const forgetPasswordStepAtom = atom(1);

export const isBusinessSetupCompleteAtom = atom(false);

export const autoFillMobileAtom = atom(false);

export const userFilesAtom = atom({});

export const userStatusAtom = atom(TokenUtils.getDecoded()?.Status || null);
